import React from 'react'
import { string } from 'prop-types'
import TypingGame from '@/component/Primitive/TypingGame'

const TypingGameWidget = ({ link }) => {
  if (!link) return <></>
  return <TypingGame link={link} />
}

TypingGameWidget.propTypes = {
  link: string
}

export default TypingGameWidget
