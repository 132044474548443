import { string } from 'prop-types'
import React from 'react'
import styles from './TypingGame.module.scss'
import Container from '../Container'

const TypingGame = ({ link }) => {
  return (
    <Container center gutter size="large" className={styles.ContainerWrap}>
      <div className={styles.Container}>
        <iframe title="typing game" src={link} className={styles.IFrame} />
      </div>
    </Container>
  )
}
TypingGame.propTypes = {
  link: string.isRequired
}

export default TypingGame
